import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import appConfig from 'configs/app.config';
import {onSignOutSuccess, setSessionMessage} from '../auth/sessionSlice';
import Cookies from 'js-cookie';

const unauthorizedCode = [401, 419];

const UPLOAD_ENDPOINTS = [
    'updateContractWarranty',
    'createContractWarranty',
    'createContractDocument',
    'updateContractDocument',
    'createEmployeeDocument',
    'updateEmployeeDocument',
];

const DOWNLOAD_ENDPOINTS = ['generateAfd'];

const baseQuery = fetchBaseQuery({
    baseUrl: appConfig.apiPrefix,
    credentials: 'include',
    prepareHeaders: (headers, {getState, endpoint}) => {
        const token = getState().auth.token;
        const csrf = Cookies.get('XSRF-TOKEN');

        if (csrf) {
            headers.set('X-Xsrf-Token', csrf);
        }

        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        headers.set('Accept', 'application/json');

        if (!UPLOAD_ENDPOINTS.includes(endpoint) && !headers.has('Content-Type')) {
            headers.set('Content-Type', 'application/json');
        }

        if (DOWNLOAD_ENDPOINTS.includes(endpoint)) {
            headers.set('Content-Type', 'application/octet-stream');
        }

        return headers;
    },
});

const baseQueryWithLogout = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result?.data?.message) {
        api.dispatch(setSessionMessage({message: result?.data.message, type: 'success'}));
    }

    if (result?.error) {
        api.dispatch(setSessionMessage({message: result?.error.data.message, type: 'danger'}));

        if (unauthorizedCode.includes(result?.error?.status)) {
            api.dispatch(onSignOutSuccess());
        }
    }

    return result;
};

export const apiSlice = createApi({
    reducerPath: 'reduxApi',
    baseQuery: baseQueryWithLogout,
    tagTypes: [
        'Subscriber',
        'Plan',
        'Ref',
        'User',
        'Company',
        'Group',
        'BusinessUnit',
        'CompetenceCenter',
        'Company',
        'Oportunity',
        'OportunityHeader',
        'ConfigOportunityHeader',
        'ConfigOportunity',
        'Contact',
        'ContractBant',
        'ContractSpiin',
        'Solution',
        'ContractPhase',
        'Contract',
        'ContractHeader',
        'ContractWarranty',
        'ContractRenewal',
        'ContractDocument',
        'ContractFinancialScheduling',
        'Income',
        'ContractProject',
        'TransactionalEmail',
        'TransactionalEmailUser',
        'HumanusUsersCount',
        'HumanusUsers',
        'Marketing',
        'MarketingBoardPhases',
        'Goal',
        'GoalTotal',
        'SalesmanGoal',
        'SalesmanGoalTotal',
        'PipelineBlocks',
        'PipelineAging',
        'PipelineCicle',
        'PipelineGoals',
        'PipelineLast5Oppt',
        'PipelineChartData',
        'Employee',
        'EmployeeDocument',
        'EmployeeDependent',
        'EmployeeCareer',
        'Excuse',
        'Visit',
        'VisitsReportChartData',
        'HandoverData',
        'SalesmanGoalsReport',
        'TimeClock',
    ],
    keepUnusedDataFor: process.env.NODE_ENV === 'development' ? 1 : 60,
    refetchOnFocus: true,
    refetchOnReconnect: true,
    endpoints: builder => ({}),
});
